import axios from "axios";

export const list = (params, page, pageSize) => {
  return axios.get("/parkingRecord/list", {
    params: {
      ...params,
      page,
      pageSize,
    },
  });
};

export const listSnapImages = (parkingRecordId, page, pageSize) => {
  return axios.get(`/parkingRecord/${parkingRecordId}/snapImages`, {
    params: {
      page,
      pageSize,
    },
  });
};

export const detail = parkingRecordId => {
  return axios.get(`/parkingRecord/${parkingRecordId}/detail`);
};
