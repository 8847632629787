import { reactive, ref } from "vue";
import { list } from "@/api/info/parkingRecordApi";
import useMessage from "@/hooks/useMessage";
const useParkingRecord = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);
  const parkingStates = ref([
    { value: -1, label: "无效作废" },
    { value: 3, label: "图片待录入" },
    { value: 0, label: "在停" },
    /*  { value: 1, label: "已出场" },*/
    { value: 2, label: "已完成" },
  ]);

  const payStates = ref([
    { value: 0, label: "待支付" },
    { value: 1, label: "已预缴" },
    { value: 2, label: "已全缴" },
  ]);

  //{0:未知,1:蓝,2:黄,3:黑,4:白,5:绿}'
  const plateColors = ref([
    { value: 0, label: "未知" },
    { value: 1, label: "蓝牌" },
    { value: 2, label: "黄牌" },
    { value: 3, label: "黑牌" },
    { value: 4, label: "白牌" },
    { value: 5, label: "绿牌" },
  ]);

  //{0:未知,1:蓝,2:黄,3:黑,4:白,5:绿}'
  const parkingAbnormalTypes = ref([
    { value: 0, label: "正常停车" },
    { value: 4, label: "重复入场" },
    { value: 5, label: "地磁误报" },
    { value: 6, label: "电动车占位" },
    { value: 7, label: "其他" },
  ]);

  const parkingRecordVo = reactive({
    parkingLotId: "",
    parkingLotName: "",
    slotNum: "",
    plateNumber: "",
    inTime: "",
    outTime: "",
    state: "",
    parkingTime: "",
    phone: "",
    nickName: "",
    payState: "",
    shouldPayAmount: "",
    discountAmount: "",
    couponValue: "",
    paidAmount: "",
    oweAmount: "",
    snapPics: "",
    inPic: "",
    outPic: "",
  });

  const columns = [
    {
      title: "序号",
      align: "center",
      width: "80px",
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "车场名称",
      align: "center",
      dataIndex: "parkingLotName",
    },
    {
      title: "泊位号",
      align: "center",
      dataIndex: "slotNum",
    },
    {
      title: "车牌号",
      align: "center",
      dataIndex: "plateNumber",
      slots: { customRender: "plateNumber" },
    },
    {
      title: "进场时间",
      align: "center",
      width: "200px",
      dataIndex: "inTime",
    },
    {
      title: "离场时间",
      align: "center",
      width: "200px",
      dataIndex: "outTime",
    },
    {
      title: "停车时长",
      align: "center",
      dataIndex: "parkingTime",
      slots: { customRender: "parkingTime" },
    },
    {
      title: "停车状态",
      align: "center",
      dataIndex: "state",
      slots: { customRender: "state" },
    },
    {
      title: "支付状态",
      align: "center",
      dataIndex: "payState",
      slots: { customRender: "payState" },
    },
    {
      title: "应付金额（元）",
      align: "center",
      dataIndex: "shouldPayAmount",
      slots: { customRender: "shouldPayAmount" },
    },
    {
      title: "已付金额（元）",
      align: "center",
      dataIndex: "paidAmount",
      slots: { customRender: "paidAmount" },
    },
    {
      title: "待付金额（元）",
      align: "center",
      dataIndex: "oweAmount",
      slots: { customRender: "oweAmount" },
    },
    {
      title: "操作",
      align: "center",
      slots: { customRender: "operation" },
    },
  ];
  const money = m => {
    if (typeof m !== "undefined") {
      return (m / 100).toFixed(2);
    }
    return "";
  };
  const timeF = m => {
    if (typeof m !== "undefined") {
      return (m / 60).toFixed(0);
    }
    return "";
  };
  const getParkingTime = t => {
    if (typeof t !== "undefined") {
      var h = ((t - (t % 3600)) / 3600).toFixed(0);
      var s = ((t % 3600) / 60).toFixed(0);
      return h == 0 ? s + "分钟" : h + "小时" + (s == 0 ? "" : s + "分钟");
    }
    return "";
  };
  const loadData = async (page, pageSize) => {
    loading.value = true;
    let p = {
      parkingLotId: params.parkingLotId,
      plateNumber: params.plateNumber,
      parkingAbnormalType: params.parkingAbnormalType,
      slotNum: params.slotNum,
      state: params.state, //停车状态-1:无效作废,0:已入场,1:已出场,2:已完成
      payState: params.payState, //支付状态 0-待缴 1-已预缴 2-已全缴
      carInArr: params.carInArr ? params.carInArr.toString() : [],
      carOutArr: params.carOutArr ? params.carOutArr.toString() : [],
    };
    try {
      let res = await list(p, page, pageSize);
      dataList.list = res.data;
      total.value = res.total;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  const params = reactive({
    parkingLotId: null,
    plateNumber: "",
    plateColor: null,
    parkingAbnormalType: null,
    slotNum: "",
    state: null, //停车状态-1:无效作废,0:已入场,1:已出场,2:已完成
    payState: null, //支付状态 0-待缴 1-已预缴 2-已全缴
    carInArr: [],
    carOutArr: [],
  });
  return {
    loading,
    params,
    dataList,
    total,
    parkingStates,
    payStates,
    plateColors,
    parkingAbnormalTypes,
    parkingRecordVo,
    columns,
    loadData,
    money,
    timeF,
    getParkingTime,
  };
};
export default useParkingRecord;
